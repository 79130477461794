




















































import { Component, Vue, Model } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import { UserResponseType } from '@/types/user'
import { AllVendorsType, VendorOptionsType } from '@/types/vendors'

export type ReportsCreateFormType = {
  REPORT_TYPE: null
  DATE_FROM: string
  DATE_TO: string
  NAME: string
  VENDOR_ID: string | null
}

@Component({
  components: { DatePickerCommon, SelectCommon, InputCommon },
})
export default class ReportsCreateForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  form: ReportsCreateFormType = {
    REPORT_TYPE: null,
    DATE_FROM: '',
    DATE_TO: '',
    NAME: '',
    VENDOR_ID: null,
  }

  vendorOptions: VendorOptionsType[] = []

  get userData(): UserResponseType {
    return this.$store.getters['user/user']
  }

  get vendors(): AllVendorsType[] {
    return this.$store.getters['vendors/allVendors']
  }

  handleAddReport(): void {
    if (this.userData.role !== 'admin') {
      this.form.VENDOR_ID = this.userData.vendor_id
    }

    this.$store.dispatch('transactions/reportsCreate', this.form)
    this.handleCloseDialog()
    this.$emit('add-report')
  }

  handleCloseDialog(): void {
    this.$emit('change', false)
  }

  async setVendors(): Promise<void> {
    this.vendors.forEach((vendor: AllVendorsType) => {
      this.vendorOptions.push({
        label: vendor.name,
        value: vendor.id,
      })
    })
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch('user/getUserData')
    await this.$store.dispatch('vendors/getAllVendors')
    await this.setVendors()
  }
}




































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({
  components: { TableColumnHeader },
})
export default class ReportsTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  reports!: object[]

  dateFilter = ''
  statusFilter = null

  reportsStatus = {
    finished: 'Готов',
    new: 'Новый',
    in_progress: 'В процессе',
  }
  statusOptions = [
    { label: 'Готов', value: 'finished' },
    { label: 'Новый', value: 'new' },
    { label: 'В процессе', value: 'in_progress' },
  ]

  get userRole() {
    return this.$store.getters['user/user'].role
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleShowUserInfo(id: string): void {
    this.$emit('show-user-info', id)
  }

  handleShowVendorInfo(id: string): void {
    this.$emit('show-vendor-info', id)
  }

  handleDownloadReport(id: string): void {
    const link = document.createElement('a')
    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + `/api/file/downloadId/${id}`
    )
    link.setAttribute('download', 'download')
    link.click()
    link.remove()
  }
}

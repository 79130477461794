var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"reports-table not-clickable",attrs:{"data":_vm.reports},on:{"sort-change":_vm.handleChangeSort}},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"ID","label":"Id","min-width":"90","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"ID","label":"Id","inputLabel":"Id"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"USER_LOGIN","label":"Участник","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"USER_LOGIN","label":"Участник","inputLabel":"Участник"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.handleShowUserInfo(row.USER_ID)}}},[_vm._v(" "+_vm._s(row.USER_LOGIN)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"DATE","label":"Дата создания","min-width":"150","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"date","columnName":">=DATE","label":"Дата создания","inputLabel":"Дата создания"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"prop":"REPORT_NAME","label":"Отчет","min-width":"150","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"reports-table__download link",on:{"click":function($event){return _vm.handleDownloadReport(row.FILE_ID)}}},[_vm._v(" "+_vm._s(row.REPORT_NAME)+" ")])]}}])}),(_vm.userRole !== 'user')?_c('el-table-column',{attrs:{"sortable":"custom","prop":"VENDOR_NAME","label":"Вендор","min-width":"200","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"VENDOR_NAME","label":"Вендор","inputLabel":"Вендор"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(+row.VENDOR_ID)?_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.handleShowVendorInfo(row.VENDOR_ID)}}},[_vm._v(" "+_vm._s(row.VENDOR_NAME)+" ")]):_c('div',[_vm._v("Все вендоры")])]}}],null,false,2802387980)}):_vm._e(),_c('el-table-column',{attrs:{"sortable":"custom","prop":"STATUS","label":"Статус","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"select","columnName":"STATUS","label":"Статус","inputLabel":"Статус","options":_vm.statusOptions},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.reportsStatus[row.STATUS]))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
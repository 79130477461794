







































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  VendorsListItemType,
  UsersListItemType,
  ActivationsListItemType,
  ReportsListItemType,
} from '@/types/transactions'
import VendorsTable from '@/components/transactions/VendorsTable.vue'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import FilterTags from '@/components/common/FilterTags.vue'
import Drawer from '@/components/common/Drawer.vue'
import UserDetail from '@/components/transactions/UserDetail.vue'
import CodesDetail from '@/components/codes/CodesDetail.vue'
import { CodesListItemType } from '@/types/codes'
import VendorsDetail from '@/components/vendors/VendorsDetail.vue'
import UsersTable from '@/components/transactions/UsersTable.vue'
import { AccrualsListItemType } from '@/types/accruals'
import { OrdersListItemType } from '@/types/orders'
import OrdersDetail from '@/components/orders/OrdersDetail.vue'
import AccrualsDetail from '@/components/accrual/AccrualsDetail.vue'
import { MembersListItemType } from '@/types/members'
import MembersDetail from '@/components/members/MembersDetail.vue'
import ActivationsTable from '@/components/transactions/ActivationsTable.vue'
import ReportsTable from '@/components/transactions/ReportsTable.vue'
import ReportsCreateForm from '@/components/transactions/ReportsCreateForm.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import { Message } from 'element-ui'

@Component({
  components: {
    DialogInfo,
    ReportsCreateForm,
    ReportsTable,
    ActivationsTable,
    MembersDetail,
    AccrualsDetail,
    OrdersDetail,
    UsersTable,
    VendorsDetail,
    CodesDetail,
    UserDetail,
    Drawer,
    FilterTags,
    VendorsTable,
  },
})
export default class Transactions extends Vue {
  loading = false
  loadingInfo = false

  tabs = ['Вендоры', 'Участники', 'Активации Участников', 'Отчеты по вендорам']

  filters: TableFilterType[] = []
  order: TableOrderType | Record<string, any> = {}
  page = 1

  selectedTab = 'Вендоры'

  vendorsFilterNames = {
    ID: 'Id',
    '>=DATE': 'Дата',
    DEBIT: 'Тип операции',
    AMOUNT: 'Сумма',
    USER_LOGIN: 'Пользователь',
    CODE_VALUE: 'Код',
    VENDOR_NAME: 'Вендор',
  }
  showUserDetail = false
  showVendorDetail = false
  showCodeDetail = false
  codesStatus = {
    NEW: 'Новый',
    REQUESTED: 'Ожидает',
    ACTIVATED: 'Активированный',
  }

  usersFilterNames = {
    DATE: 'Дата',
    USER_LOGIN: 'Пользователь',
    AMOUNT: 'Сума',
    DEBIT: 'Тип операции',
    ENTITY_TYPE: 'Тип транзакции',
    ORDER_EXT_ID: 'Заказ',
    ORDER_PRICE: 'Стоимость заказа',
    ACCRUAL_EXT_ID: 'Начисление',
    ACCRUAL_PRICE: 'Сумма начисления',
    CODE_VALUE: 'Код',
    VENDOR_NAME: 'Вендор',
  }
  showOrderDetail = false
  showAccrualDetail = false
  showMemberDetail = false

  activationsFilterNames = {
    DATE: 'Дата',
    USER_LOGIN: 'Пользователь',
    AMOUNT: 'Сума',
    CODE_VALUE: 'Код',
    VENDOR_NAME: 'Вендор',
  }

  reportsFilterNames = {
    ID: 'Id',
    USER_LOGIN: 'Пользователь',
    DATE: 'Дата заказа',
    VENDOR_NAME: 'Вендор',
    STATUS: 'Статус',
  }
  showAddReportsForm = false
  addReportSuccess = false

  get exportUrl(): TableExportType {
    switch (this.selectedTab) {
      case 'Вендоры':
        break
      case 'Участники':
        return this.$store.getters['transactions/usersExport']
      case 'Активации Участников':
        return this.$store.getters['transactions/activationsExport']
      case 'Отчеты по вендорам':
        return this.$store.getters['transactions/reportsExport']
    }

    return this.$store.getters['transactions/vendorsExport']
  }

  get total(): string {
    switch (this.selectedTab) {
      case 'Вендоры':
        break
      case 'Участники':
        return this.$store.getters['transactions/usersTotal']
      case 'Активации Участников':
        return this.$store.getters['transactions/activationsTotal']
      case 'Отчеты по вендорам':
        return this.$store.getters['transactions/reportsTotal']
    }

    return this.$store.getters['transactions/vendorsTotal']
  }

  get showedCount(): string | number {
    if (this.total) {
      return 10 * this.page > parseInt(this.total) ? this.total : 10 * this.page
    } else {
      return 0
    }
  }

  //Вендоры
  get vendors(): VendorsListItemType[] {
    return this.$store.getters['transactions/vendorsList']
  }

  get userDetail(): UsersListItemType {
    return this.$store.getters['users/usersDetail']
  }

  get codesDetail(): CodesListItemType {
    return this.$store.getters['codes/codesDetail']
  }

  get vendorDetail(): VendorsListItemType {
    return this.$store.getters['vendors/vendorsDetail']
  }

  //Пользователи
  get users(): UsersListItemType[] {
    return this.$store.getters['transactions/usersList']
  }

  get accrualDetail(): AccrualsListItemType {
    return this.$store.getters['accruals/accrualsDetail']
  }

  get orderDetail(): OrdersListItemType {
    return this.$store.getters['orders/ordersDetail']
  }

  get memberDetail(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  //Активации
  get activations(): ActivationsListItemType {
    return this.$store.getters['transactions/activationsList']
  }

  //Отчеты
  get reports(): ReportsListItemType {
    return this.$store.getters['transactions/reportsList']
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data: { target: string; data: string }) => {
      params[data.target] = data.data
    })

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  async handleChangePage(): Promise<void> {
    const tabs = document.querySelector('[data-scroll="table"]') as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }
    params.filter = this.tableFilters
    params.order = this.tableOrder
    params.offset = (this.page - 1) * 10

    this.loading = true

    switch (this.selectedTab) {
      case 'Вендоры':
        await this.$store.dispatch('transactions/getVendorsList', params)
        break
      case 'Участники':
        await this.$store.dispatch('transactions/getUsersList', params)
        break
      case 'Активации Участников':
        await this.$store.dispatch('transactions/getActivationsList', params)
        break
      case 'Отчеты по вендорам':
        await this.$store.dispatch('transactions/getReportsList', params)
        break
    }

    this.loading = false
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data
    this.handleChangePage()
  }

  handleAddTableFilter(data: TableFilterType): void {
    let names = {}

    switch (this.selectedTab) {
      case 'Вендоры':
        names = this.vendorsFilterNames
        break
      case 'Участники':
        names = this.usersFilterNames
        break
      case 'Активации Участников':
        names = this.activationsFilterNames
        break
      case 'Отчеты по вендорам':
        names = this.reportsFilterNames
        break
    }

    const foundFilterIndex = this.filters.findIndex(
      (filter) => filter.target === data.target
    )

    if (foundFilterIndex === -1) {
      if (data.data === '') {
        return
      }

      this.filters.push({
        data: data.data,
        target: data.target,
        name: names[data.target],
      })
    } else {
      if (data.data === '') {
        this.handleDeleteFilter(foundFilterIndex)
      }

      this.filters.forEach((item) => {
        if (item.target === data.target) {
          this.handleDeleteFilter(foundFilterIndex)

          this.filters.push({
            data: data.data,
            target: data.target,
            name: names[data.target],
          })
        }
      })
    }
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  //Вендоры
  async handleShowUserInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showUserDetail = true

    await this.$store.dispatch('users/getUsersDetail', id)

    this.loadingInfo = false
  }

  async handleShowCodeInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showCodeDetail = true

    await this.$store.dispatch('codes/getCodesDetail', id)

    this.loadingInfo = false
  }

  async handleShowVendorInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showVendorDetail = true

    await this.$store.dispatch('vendors/getVendorsDetail', id)

    this.loadingInfo = false
  }

  async handleShowOrderInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showOrderDetail = true

    await this.$store.dispatch('orders/getOrdersDetail', id)

    this.loadingInfo = false
  }

  async handleShowAccrualInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showAccrualDetail = true

    await this.$store.dispatch('accruals/getAccrualsDetail', id)

    this.loadingInfo = false
  }

  async handleShowMemberInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showMemberDetail = true

    await this.$store.dispatch('members/getMembersDetail', id)

    this.loadingInfo = false
  }

  lazyDownload(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.exportUrl.link
    )
    link.setAttribute('download', this.exportUrl.name)
    link.click()
    link.remove()
  }

  async handleExportTable(): Promise<void> {
    switch (this.selectedTab) {
      case 'Вендоры':
        await this.$store.dispatch('transactions/getExportVendors', {
          filter: this.tableFilters,
          order: this.tableOrder,
        })

        if (
          this.exportUrl.type === 'error' ||
          this.exportUrl.type === 'queue'
        ) {
          Message.error(this.exportUrl.text || 'Ошибка')
        } else {
          this.lazyDownload()
        }
        break
      case 'Участники':
        await this.$store.dispatch('transactions/getExportUsers', {
          filter: this.tableFilters,
          order: this.tableOrder,
        })
        if (
          this.exportUrl.type === 'error' ||
          this.exportUrl.type === 'queue'
        ) {
          Message.error(this.exportUrl.text || 'Ошибка')
        } else {
          this.lazyDownload()
        }
        break
      case 'Активации Участников':
        await this.$store.dispatch('transactions/getExportActivations', {
          filter: this.tableFilters,
          order: this.tableOrder,
        })
        if (
          this.exportUrl.type === 'error' ||
          this.exportUrl.type === 'queue'
        ) {
          Message.error(this.exportUrl.text || 'Ошибка')
        } else {
          this.lazyDownload()
        }
        break
      case 'Отчеты по вендорам':
        await this.$store.dispatch('transactions/getExportReports', {
          filter: this.tableFilters,
          order: this.tableOrder,
        })
        if (
          this.exportUrl.type === 'error' ||
          this.exportUrl.type === 'queue'
        ) {
          Message.error(this.exportUrl.text || 'Ошибка')
        } else {
          this.lazyDownload()
        }
        break
    }
  }

  handleTabClick(data: { id: number; tab: string }): void {
    this.filters = []
    this.order = []
    this.page = 1
    this.selectedTab = data.tab
  }

  handleAddReportForm(): void {
    this.showAddReportsForm = !this.showAddReportsForm
  }

  handleShowSuccess(): void {
    this.addReportSuccess = !this.addReportSuccess
  }

  @Watch('filters')
  onFiltersChange(): void {
    this.handleChangePage()
  }

  @Watch('page')
  onPageChange(): void {
    this.handleChangePage()
  }

  async mounted(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('transactions/getVendorsList')

    this.loading = false
  }
}
